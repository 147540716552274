import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function StreamerTable4({ newData }) {

    return (
        <div className='card' style={{ height: '100%' }}>
            <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
                <b>Market Depth</b>
            </div>
            <div className='card-body'>
                <TableContainer component={Paper}>
                    <Table className="table customize-table" aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell>Bids</TableCell>
                                <TableCell>BQty</TableCell>
                                <TableCell>Bid Price</TableCell>
                                <TableCell>Ask Price</TableCell>
                                <TableCell>AQty</TableCell>
                                <TableCell>Bids</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {newData.map(new_data => {
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell>{new_data.B_BIDS_1}</TableCell>
                                            <TableCell>{new_data.BQTY_1}</TableCell>
                                            <TableCell>{new_data.BIDPRICE_1}</TableCell>
                                            <TableCell>{new_data.ASKPRICE_1}</TableCell>
                                            <TableCell>{new_data.AQTY_1}</TableCell>
                                            <TableCell>{new_data.A_BIDS_1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{new_data.B_BIDS_2}</TableCell>
                                            <TableCell>{new_data.BQTY_2}</TableCell>
                                            <TableCell>{new_data.BIDPRICE_2}</TableCell>
                                            <TableCell>{new_data.ASKPRICE_2}</TableCell>
                                            <TableCell>{new_data.AQTY_2}</TableCell>
                                            <TableCell>{new_data.A_BIDS_2}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{new_data.B_BIDS_3}</TableCell>
                                            <TableCell>{new_data.BQTY_3}</TableCell>
                                            <TableCell>{new_data.BIDPRICE_3}</TableCell>
                                            <TableCell>{new_data.ASKPRICE_3}</TableCell>
                                            <TableCell>{new_data.AQTY_3}</TableCell>
                                            <TableCell>{new_data.A_BIDS_3}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{new_data.B_BIDS_4}</TableCell>
                                            <TableCell>{new_data.BQTY_4}</TableCell>
                                            <TableCell>{new_data.BIDPRICE_4}</TableCell>
                                            <TableCell>{new_data.ASKPRICE_4}</TableCell>
                                            <TableCell>{new_data.AQTY_4}</TableCell>
                                            <TableCell>{new_data.A_BIDS_4}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{new_data.B_BIDS_5}</TableCell>
                                            <TableCell>{new_data.BQTY_5}</TableCell>
                                            <TableCell>{new_data.BIDPRICE_5}</TableCell>
                                            <TableCell>{new_data.ASKPRICE_5}</TableCell>
                                            <TableCell>{new_data.AQTY_5}</TableCell>
                                            <TableCell>{new_data.A_BIDS_5}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default StreamerTable4;
