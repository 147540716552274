import React from 'react';
import { useState, useEffect } from 'react';
import _ from "lodash";
import './style.css';
import { makeStyles } from "@material-ui/core/styles";
import { Responsive, WidthProvider } from "react-grid-layout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Minimize as MinimizeIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Select, MenuItem } from "@material-ui/core";
import Graph from "./Graph1";
import Streamertable3 from './Streamer-table3';
import StreamerTable4 from './Streamer-table4';
import StreamerTable5 from './Streamer-table5';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    // backgroundColor: 'rgb(57, 45, 125)',
    // fontColor: '#fff'
  }
});

const stickyHeaderStyle = {
  // backgroundColor: '#333',
  // color: '#fff',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}

const stickyCellStyle = {
  // backgroundColor: '#eee',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}

const ButtonHeight = {
  height: '87%',
  paddingBottom: '10px',
}

function ShowcaseLayout() {
  const [sse1, setSSE1] = useState(null);
  const [sse2, setSSE2] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [defaultInstrument, setdefaultInstrument] = useState('Select Segment');
  const [defaultData, setDefaultData] = useState("Instrument Type");
  const [defaultSymbolData, setDefaultSymbolData] = useState("Select Symbol");
  const [defaultExpiryData, setDefaultExpiryData] = useState("Expiry Date");
  const [instrumentType, setInstrumentType] = useState([{
    name: 'Future',
    value: 'FUT'
  },
  {
    name: 'Options',
    value: 'OPT'
  }]);
  const [instrument, setInstrument] = useState([{
    name: "Equity Derivatives",
    value: 'STK',
  },
  {
    name: "Index Derivatives",
    value: 'IDX',
  },
  {
    name: "Currency Derivatives",
    value: 'CUR',
  },
  {
    name: "Commodity Derivatives",
    value: 'COM',
  },
  {
    name: "US Stocks",
    value: 'USA',
  }])
  const classes = useStyles();
  const [token, setToken] = useState();
  const [data, setData] = useState([]);
  const [firstRow, setFirstRow] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [instrumentApi, setInstrumentApi] = useState([]);
  const [streamerToken, setStreamerToken] = useState([]);
  const [symbol, setSymbol] = useState([]);
  const [expiryDate, setExpiryDate] = useState([]);
  const [req_segment, setReq_segment] = useState();
  const [req_instrument_type, setReq_instrument_type] = useState();
  const [req_symbol, setReq_symbol] = useState();
  const [marketTop5Data, setMarketTop5Data] = useState([]);
  const [xAxis, setxAxis] = useState([]);
  const [yAxis, setyAxis] = useState([]);
  const [marketContractSynopsis, setMarketContractSynopsis] = useState([]);
  const [dataMarketDepth, setDataMarketDepth] = useState([]);
  let newArr = [];
  let new_SymbolArr = [];
  let new_ExpiryDate = [];
  let tokenNumber;
  const setupSSEConnections = (a) => {
    if (a === 'default') {
      if (sse2) {
        sse2.close();
      }
      if (sse1) {
        sse1.close();
      }
      const newSSE1 = new EventSource('https://www1.nseix.com/sse_events/market-watch');
      newSSE1.onmessage = (event) => {
        if (event.data.length > 0) {
          const jsonData = JSON.parse(event.data);
          setData(jsonData.MBP_data_Market_Watch);
          setFirstRow(jsonData.MBP_data_Market_Watch[0]?.token_number);
        }
      }
      newSSE1.onerror = (event) => {
        console.log('SSE1: Error:', event.data);
        newSSE1.close();
      }
      setSSE1(newSSE1);
    }
    else {
      let newSSE2 = null;
      if (sse2) {
        sse2.close();
      }
      if (sse1) {
        sse1.close();
      }
      newSSE2 = new EventSource(`https://www1.nseix.com/sse_events/market-watch/${a}`);
      newSSE2.onmessage = (event) => {
        if (event.data.length > 0) {
          const jsonData = JSON.parse(event.data);
          setData(jsonData.MBP_data_Market_Watch);
          setFirstRow(jsonData.MBP_data_Market_Watch[0]?.token_number);
        }
      }
      newSSE2.onerror = (event) => {
        console.log('SSE2: Error:', event.data);
        newSSE2.close();
      }
      setSSE2(newSSE2);
    }
  }
  const handleRowClick = (row) => {
    setSelectedRow(row.token_number);
  }

  const getStreamerInstrumentType = async (e) => {
    setdefaultInstrument(e.target.value);
    setToken(e.target.value);
    try {
      setReq_segment(e.target.value);
      const req_segment1 = e.target.value;
      const resp = await fetch('http://localhost:4000/api/streamer-filters?segment=' + req_segment1);
      const jsonData = await resp.json();
      if (jsonData != undefined) {
        await jsonData.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
        })
        setStreamerToken(newArr);
      } else {
        console.log('token number is empty');
      }
    } catch (error) {
      console.log('error in fetching data', error);
    };
    setupSSEConnections(newArr);
  };
  const instrumentTypeChange = async (e) => {
    newArr = [];
    setDefaultData(e.target.value)
    try {
      setReq_instrument_type(e.target.value);
      const req_instrument_type1 = e.target.value;
      const resp1 = await fetch('http://localhost:4000/api/streamer-filters?segment=' + req_segment + '&instrument_type=' + req_instrument_type1);
      const jsonData1 = await resp1.json();
      if (jsonData1 != undefined) {
        await jsonData1.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
          new_SymbolArr.push(data.MCM_SYMBOL);
        });
        setInstrumentApi(newArr);
        setSymbol(new_SymbolArr);
      } else {
        console.log('token number is empty');
      }
    } catch (e) {
      console.log('error in catch data', e);
    }
    setupSSEConnections(newArr);
  };
  const symbolChange = async (e) => {
    newArr = [];
    setDefaultSymbolData(e.target.value);
    try {
      setReq_symbol(e.target.value);
      const req_symbol1 = e.target.value;
      const resp2 = await fetch('http://localhost:4000/api/streamer-filters?segment=' + req_segment + '&instrument_type=' + req_instrument_type + '&symbol=' + req_symbol1);
      const jsonData2 = await resp2.json();
      if (jsonData2 != undefined) {

        await jsonData2.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
          new_ExpiryDate.push(data.MCM_EXPIRY_DT);
        });
        setExpiryDate(new_ExpiryDate);
      } else {
        console.log('token number is empty');
      }
    } catch (e) {
      console.log('error in catch data', e);
    }
    setupSSEConnections(newArr);
  }

  const handleExpiryDate = async (e) => {
    newArr = [];
    setDefaultExpiryData(e.target.value)
    try {
      const req_expiry_date1 = e.target.value;
      const resp3 = await fetch('http://localhost:4000/api/streamer-filters?segment=' + req_segment + '&instrument_type=' + req_instrument_type + '&symbol=' + req_symbol + '&expiry_date=' + req_expiry_date1);
      const jsonData3 = await resp3.json();
      if (jsonData3 != undefined) {
        await jsonData3.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
        });
      } else {
        console.log('token number is empty');
      }
    } catch (e) {
      console.log('error in catch data', e);
    }
    setupSSEConnections(newArr);
  }

  const reset = () => {
    setSelectedRow([]);
    setdefaultInstrument(defaultInstrument + 'Select Segment');
    setDefaultData(defaultData + 'Instrument Type');
    setDefaultSymbolData(defaultSymbolData + 'Select Symbol');
    setDefaultExpiryData(defaultExpiryData + 'Expiry Date');
    setupSSEConnections('default');
  }
  tokenNumber= selectedRow ? selectedRow : firstRow;

  useEffect(() => {
    setSSE2(null);
    setupSSEConnections('default');
    const eventSource = new EventSource(`https://www1.nseix.com/sse_events/market-depth_data/${tokenNumber}`);
    eventSource.onmessage = (event) => {
        const jsonData = JSON.parse(event.data)
        jsonData.market_graph_data.token_graph_data.map((val)=>{
          const date = new Date(val.Y_LTP);
          const formattedTimeStamp = date.toLocaleString();
          setxAxis(val.X_LTT);
          setyAxis(formattedTimeStamp);
        });
        setMarketTop5Data(jsonData.market_book_data.token_trade_data);
        setDataMarketDepth(jsonData.market_depth_data.token_market_depth_data);
        setMarketContractSynopsis(jsonData.contract_synopsis_data.token_market_contract_synopsis_data);
    };

    eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSource.close();
    };

    return () => {
      setSSE2(null);
      eventSource.close();
    }
  }, [tokenNumber]);

  const handleMinimized = () => {
    setMinimized(!minimized);
  }
  const gridItems = [
    { id: 1, name: "Item One" },
    { id: 2, name: "Item Two" },
    { id: 3, name: "Item Three" },
    { id: 4, name: "Item Four" },
    { id: 5, name: "Item Five" },


  ];
  const layout = [
    { i: '1', x: 0, y: 0, w: 12, h: 2.5 },
    { i: '2', x: 0, y: 0, w: 3, h: 3 },
    { i: '3', x: 3, y: 3, w: 3, h: 3 },
    { i: '4', x: 6, y: 1, w: 3, h: 3 },
    { i: '5', x: 12, y: 2, w: 3, h: 3 },

  ];
  const [rowHeight] = useState(150);
  return (
    <>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-2">
            <Select defaultValue={defaultInstrument}
              className="customize-input-group quotes-select-filter"
              onChange={getStreamerInstrumentType}
              value={defaultInstrument}
            >
              <MenuItem value={defaultInstrument} selected="selected" disabled>Select Segment</MenuItem>
              {instrument && instrument.map((x, idx) => {
                return <MenuItem key={x + idx} value={x.value} >
                  {x.name}
                </MenuItem>
              })}
            </Select>
          </div>
          <div className="col-md-2">
            <Select defaultValue={defaultData}
              className="customize-input-group quotes-select-filter"
              onChange={instrumentTypeChange}
              value={defaultData}
            >
              <MenuItem value={defaultData} selected="selected" disabled>Instrument Type</MenuItem>
              {instrumentType.map((x, idx) => {
                return <MenuItem key={x + idx} value={x.value}>
                  {x.name}
                </MenuItem>
              })}
            </Select>
          </div>
          <div className="col-md-2">
            <Select defaultValue={defaultSymbolData}
              className="customize-input-group quotes-select-filter"
              onChange={symbolChange}
              value={defaultSymbolData}
            >
              <MenuItem value={defaultSymbolData} selected="selected" disabled>Select Symbol</MenuItem>
              {symbol ?
                symbol.map((x, idx) => {
                  return <MenuItem key={x + idx} value={x} >
                    {x}
                  </MenuItem>
                }) :
                <MenuItem selected="selected" disabled>No Symbol</MenuItem>
              }
            </Select>
          </div>
          <div className="col-md-2">
            <Select defaultValue={defaultExpiryData}
              className="customize-input-group quotes-select-filter"
              onChange={handleExpiryDate}
              value={defaultExpiryData}
            >
              <MenuItem value={defaultExpiryData} selected="selected" disabled>Expiry Date</MenuItem>
              {expiryDate.map((x, idx) => {
                return <MenuItem key={x + idx} value={x}>
                  {x}
                </MenuItem>
              })}
            </Select>
          </div>
          <div className="col-sm-1 quotes-filter">
            <button className="btn btn-orange full-width" style={{ButtonHeight}}  onClick={reset}>Clear</button>
          </div>
        </div>
      </div>
      <ResponsiveReactGridLayout
        layouts={{ lg: layout }}
        measureBeforeMount={false}
        className="layout"
        rowHeight={rowHeight}
        isDraggable={true}
        isResizable={false}
        margin={[20, 20]}
      >
        {gridItems.map((item, i) => {

          if (item.id == 1) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <div className='card' >
                <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
                  <b>Market Watch</b>
                  <IconButton edge="end" color="inherit" onClick={handleMinimized} style={{ float: 'right' }}>
                    <MinimizeIcon />
                  </IconButton>
                </div>
                {minimized ? (
                  <div style={{ display: 'none' }}></div>
                ) : (

                  <div className='card-body'>
                    <div>
                      <TableContainer component={Paper} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <Table className="table customize-table" style={{ width: '100%' }} >
                          <TableHead>
                            <TableRow className={classes.stickyHeader} style={{ backgroundColor: '#392d7d', color: 'white' }}>
                              <>
                                <TableCell style={{ color: '#fff' }}>InstrumentType
                                </TableCell>
                                <TableCell style={{ color: '#fff' }} >Symbol
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>EXPIRYDATE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>OPTION_TYPE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>STRIKE_PRICE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>BIDQTY
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>BIDPRICE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>ASKPRICE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>ASKQTY
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>LASTPRICE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>CHANGE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>PERCHANGE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>VOLUME
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>VALUE
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>ATP
                                </TableCell>
                                <TableCell style={{ color: '#fff' }}>LTT
                                </TableCell>
                              </>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((data_new) => {
                              return (<TableRow onClick={() => handleRowClick(data_new)} selected={selectedRow === data_new} style={{ backgroundColor: selectedRow === data_new.token_number ? 'lightblue' : 'inherit' }}>
                                <TableCell >{data_new.token_data[0].INSTRUMENT}</TableCell>
                                <TableCell>{data_new.token_data[0].SYMBOL}</TableCell>
                                <TableCell>{data_new.token_data[0].EXPIRYDATE}</TableCell>
                                <TableCell>{data_new.token_data[0].OPTIONTYPE}</TableCell>
                                <TableCell>{data_new.token_data[0].STRIKEPRICE}</TableCell>
                                <TableCell >{data_new.token_data[0].BIDQTY}</TableCell>
                                <TableCell>{data_new.token_data[0].BIDPRICE}</TableCell>
                                <TableCell>{data_new.token_data[0].ASKPRICE}</TableCell>
                                <TableCell >{data_new.token_data[0].ASKQTY}</TableCell>
                                <TableCell>{data_new.token_data[0].LASTPRICE}</TableCell>
                                <TableCell>{data_new.token_data[0].CHANGE}</TableCell>
                                <TableCell>{data_new.token_data[0].PERCHANGE}</TableCell>
                                <TableCell>{data_new.token_data[0].VOLUME}</TableCell>
                                <TableCell>{data_new.token_data[0].VALUE}</TableCell>
                                <TableCell>{data_new.token_data[0].ATP}</TableCell>
                                <TableCell>{data_new.token_data[0].LTT}</TableCell>
                              </TableRow>)
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>

                )
                }

              </div>
            </div>)
          }
          else if (item.id == 2) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <div className='card' style={{ height: '100%' }}>
                <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
                  <b>Market Graph </b>
                </div>
                <div className='card-body'>
                  <Graph dataX={xAxis} dataY={yAxis} />
                </div>
              </div>
            </div>)
          }
          else if (item.id == 3) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <Streamertable3 newData = {marketTop5Data} />
            </div>)
          }
          else if (item.id == 4) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >
              <StreamerTable4 newData = {dataMarketDepth} />
            </div>)
          }
          else if (item.id == 5) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <StreamerTable5 newData = {marketContractSynopsis} />
            </div>)
          }

        })}
      </ResponsiveReactGridLayout>
    </>
  );

}

export default ShowcaseLayout;
