import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Streamertable3({newData}) {
    return (
        <div className='card' style={{ height: '100%' }} >
            <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }}>
                <b>Market Book last 5 trades</b>
            </div>
            <div className='card-body' style={{ overflow: 'auto' }} >
                <Paper>
                    <TableContainer component={Paper} sx={{ maxHeight: 440 }} style={{ overflowX: 'initial' }}>
                        <Table stickyHeader className=" customize-table " aria-label="sticky table">
                            <TableHead>
                                <TableRow style={{ position: 'sticky', top: '0' }}>
                                    <TableCell>Time</TableCell>
                                    <TableCell >Price</TableCell>
                                    <TableCell>Qty</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                    newData.map((data_new) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell>{data_new.LTT_1}</TableCell>
                                                    <TableCell>{data_new.LTP_1}</TableCell>
                                                    <TableCell>{data_new.LTV_1}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{data_new.LTT_2}</TableCell>
                                                    <TableCell>{data_new.LTP_2}</TableCell>
                                                    <TableCell>{data_new.LTV_2}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{data_new.LTT_3}</TableCell>
                                                    <TableCell>{data_new.LTP_3}</TableCell>
                                                    <TableCell>{data_new.LTV_3}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{data_new.LTT_4}</TableCell>
                                                    <TableCell>{data_new.LTP_4}</TableCell>
                                                    <TableCell>{data_new.LTV_4}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{data_new.LTT_5}</TableCell>
                                                    <TableCell>{data_new.LTP_5}</TableCell>
                                                    <TableCell>{data_new.LTV_5}</TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}


export default Streamertable3;
