import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function StreamerTable5({ newData}){

    return(
        <div className='card' style={{ height: '100%' }}>
        <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
          <b>Contract Synopsis</b>
        </div>
        <div className='card-body'>
          <TableContainer component={Paper}>
            <Table className="table customize-table" aria-label="simple table">
              <TableBody >
                {newData.map(value=>{
                    return(
                <>
                <TableRow >
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>PrevCloseRate</TableCell>
                  <TableCell>{value.PREVCLOSERATE}</TableCell>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Time</TableCell>
                  <TableCell>{value.TIME}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Open</TableCell>
                  <TableCell>{value.OPEN}</TableCell>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Price</TableCell>
                  <TableCell>{value.PRICE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>High</TableCell>
                  <TableCell>{value.HIGH}</TableCell>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Qty</TableCell>
                  <TableCell>{value.QTY}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Low</TableCell>
                  <TableCell>{value.LOW}</TableCell>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Change</TableCell>
                  <TableCell>{value.CHANGE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Volume</TableCell>
                  <TableCell>{value.VOLUME}</TableCell>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Change %</TableCell>
                  <TableCell>{value.CHANGE+'%'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Avg</TableCell>
                  <TableCell>{value.AVG}</TableCell>
                  <TableCell style={{ backgroundColor: 'rgba(59, 46, 126, 0.17)', color: '#392d7d', fontWeight: 'bold' }}>Turnover (Mn)</TableCell>
                  <TableCell>{value.TURNOVER}</TableCell>
                </TableRow>
                </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
}

export default StreamerTable5;
