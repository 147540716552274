import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";
//import env from '../../config';

const ButtonHeight = {
  height: "100%",
};

class EsgIssuerFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api1: `/api/issuer-details/filters`,
      api2:'/api/issuer-details',
      selectName: [],
      selectDocumentType: [],
      name: this.props.issuerName != null ? this.props.issuerName : '',
      documentType: "",
      callback: props.callback,
      resetcallback: props.resetcallback,
    };
    this.filter = this.filter.bind(this);
    this.reset = this.reset.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
  }
  componentDidMount() {
    try {
        this.loadFilters(this.state.api1)
      this._isMounted = true;
      this.filter();
    } catch (e) {
      console.log(e);
    }
  }

  loadFilters(api) {
    fetch(api)
        .then(resp => resp.json())
        .then((resp) => {
            this.setState({ selectName: resp.names, selectDocumentType:resp.documentType });
        })
        .catch((e) => { console.log("error in loading list of filters in esg issuer details", e) })
}

  filter() {
    if (this.state.name || this.state.documentType) {
      this.state.callback(this.state.name, this.state.documentType);
    } else {
    }
  }

  reset() {
    this.setState({
      name: "",
      documentType:''
    });
    this.state.resetcallback(this.state.api2);
  }

  render() {
    return (
      <>
        <div className="mr-5 row corporate-filter-fields mt-3">
          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {this.setState({name:e.target.value}, ()=>{
                this.filter()
              });}}
              value={this.state.name}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Name
              </MenuItem>
              {this.state.selectName.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ documentType: e.target.value }, ()=>{
                  this.filter()
                });
              }}
              value={this.state.documentType}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Type of Document
              </MenuItem>
              {this.state.selectDocumentType.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <span className="red cofilter-validation-to">
            {this.state.validationTo}
          </span>
          <div className="col-md-1 pr-0 cofilter-padding">
            <button
              className="btn esg-btn full-width cofilter-btn"
              style={ButtonHeight}
              onClick={this.filter}
            >
              Go
            </button>
          </div>
          <div className="col-md-1 pr-0 cofilter-padding">
                <button className="btn btn-primary full-width cofilter-btn" style={ButtonHeight} onClick={this.reset}>Reset</button>
          </div>
        </div>
      </>
    );
  }
}
export default EsgIssuerFilter;
