import React from 'react';
import NavTabs from './components/nav-tabs';
import UsStockDataTable from './components/us-stocks-data-table';
import { parseAsHtml, removePtag } from './components/util';
import { ReactComponent as MultipleDownload } from '../public/images/multiple-download.svg';
import UsStockCaTable from './components/us-stock-ca-table';
import FindABroker from './components/findABroker';

class USStocks extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
            // notesApi: `/api/contents/notes?url=/derivative-watch`,
            eqnotesData: [],
            paramSelected: '',
            cmsApi: `/api/contents/page?url=/markets/global-stocks`,
            midContent: [],
            contents: {},
        };
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            // this.loadNotes(this.state.notesApi);
            this.loadStaticData(this.state.cmsApi);
        } catch (e) {
            console.log(e);
        }

    }

    setComponents(resp) {
        const content = resp;
        let title = "";
        const data = {};
        let midContent = [];
        content[0].field_tabs_content.forEach((cont, idx) => {
            midContent = [];
            Object.keys(cont.field_tab_view).forEach((ke, jdx) => {

                let fieldname = Object.keys(cont.field_tab_view[ke])[0];
                // let field = cont.field_tab_view[ke];
                let fieldvalue = cont.field_tab_view[ke][fieldname] || "";
                switch (fieldname) {
                    case "field_title":
                        title = removePtag(fieldvalue);
                        break;
                    case "field_para":
                        midContent.push(<div key={`para_${idx}${jdx}`} className="mt-4 static-para">
                            {parseAsHtml(fieldvalue)}</div>)
                        break;

                    case "field_subtitle":

                        midContent.push(<div className="mt-4 notes-section" key={`subtitle_${idx}${jdx}`}>
                            <h3 className="notes-title">{parseAsHtml(removePtag(fieldvalue))}</h3></div>)

                        break;
                    case "field_trade":

                        let tradecards = [];
                        fieldvalue.forEach((card, kdx) => {
                            tradecards.push(<div key={kdx} className="col-md-4">
                                <div className="trade-card">
                                    <div className="cardspacing">
                                        <div className="trade-no">
                                            {card.field_trade_card[0] && removePtag(card.field_trade_card[0].field_trade_cards)}

                                        </div>
                                        <h3>{card.field_trade_card[1] && removePtag(card.field_trade_card[1].field_title)}</h3>
                                        <p className="grey-color circ-card-content" title={"hsbhsn"}>
                                            {card.field_trade_card[2] && removePtag(card.field_trade_card[2].field_para)}</p>
                                    </div>
                                </div>
                            </div>)

                        })


                        midContent.push(<div className="row mt-4 " key={`tradec_${idx}${jdx}`}>{tradecards}</div>)

                        break;
                    case "field_trade_usstock_file":

                        let downloadcards = [];
                        fieldvalue.forEach((card, kdx) => {
                            downloadcards.push(<div key={kdx} className={fieldvalue.length > 2 ? "col-md-4" : "col-md-3"} key={`dl${idx}${jdx}${kdx}`}>
                                <div className="document-card-link">
                                    <div className="doc-icon"><MultipleDownload /></div>
                                    <a target="_blank" rel="noopener noreferrer" className="download-link" href={card.trade_usstock_url || ""}
                                        title={card.field_trade_usstock_text}>
                                        {card.field_trade_usstock_text}</a>
                                </div>
                            </div>)
                        })

                        midContent.push(<div className="row mt-4" key={`tradelink_${idx}${jdx}`}>{downloadcards}</div>)

                        break;
                    case "field_product_information":

                        let prodInfo = [];
                        fieldvalue.forEach((card, kdx) => {
                            if (card.field_product_information_cards) {


                                let imgsrc = card.field_product_information_cards[0];
                                let title = card.field_product_information_cards[1].field_title;
                                prodInfo.push(<div key={kdx} className="col-md-4 mt-4">
                                    <div className="prodinfo-card">
                                        <div className="prod-img">
                                            <img src={imgsrc.url} alt={imgsrc.alt} />
                                        </div>
                                        <h3>{card.field_product_information_cards[1] &&
                                            removePtag(title)}</h3>
                                    </div>
                                </div>)
                            }
                        })


                        midContent.push(<div className="row mt-2" key={`proInfo_${idx}${jdx}`}>{prodInfo}</div>)

                        break;
                    case "field_trade_timing":


                        midContent.push(<div key={`time_${idx}${jdx}`} className="row mt-4 mb-4 trade_calendar">
                            <div className="col-md-12">

                                <img src={fieldvalue[0].url || ""} alt={fieldvalue[0].alt} />
                                <div>
                                    {parseAsHtml((fieldvalue[1].field_para))}
                                </div>
                            </div>


                        </div>)

                        break;
                    default:
                        break;
                }

            })
            data[title] = midContent;
        });
        //const contents = 
        //this.createComponents(data)

        this.setState({ contents: data });

    }
    createComponents(data) {

        let contents = Object.keys(data).map((key) => {
            return {
                title: removePtag(key),
                component: <div className="container-fluid usstock-static">
                    {parseAsHtml(data[key])}
                </div>
            }
        });


        this.setState({ contents });
    };

    loadStaticData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                let midContent = [];
                let newresp = resp.content.field_components;

                if (newresp && newresp[0] && newresp.length > 0) {
                    this.setComponents(newresp);
                }

                this.setState({ midContent });
            })
            .catch((e) => { console.log("error in loading static data of Us Stocks", e) })
    }
    // loadNotes(api) {
    //     fetch(api)
    //         .then(resp => resp.json())
    //         .then((cards) => {
    //             let eqnotesData = [];
    //             let comnotesData = [];
    //             let curnotesData = [];
    //             let title = cards.content.field_note_components[0];
    //             eqnotesData.push(title, cards.content.field_note_components[1]);
    //             this.setState({
    //                 eqnotesData
    //             })
    //         })
    //         .catch((e) => { console.log("error in notes of Us Stocks", e) })
    // }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <NavTabs tabs={[
                                {
                                    title: "US Stocks",
                                    component: <>
                                        <UsStockDataTable filename="US Stocks" search={true} muitable={true} csv={true} 
                                            insttype={"stocks"} api={`/api/us-stocks?type=stocks`} />
                                    </>

                                },
                                // {
                                //     title: "US ETFS",
                                //     component: <>
                                //         <UsStockDataTable filename="US ETFS" search={true}
                                //             insttype={"etfs"} api={`/api/us-stocks?type=etf`} />

                                //     </>

                                // },

                            ...Object.keys(this.state.contents).map((key) => {
                                    return {
                                        title: removePtag(key),
                                        component: <div className="container-fluid usstock-static">
                                            {parseAsHtml(this.state.contents[key])}
                                        </div>
                                    }
                                }),
                                {
                                    title: "Corporate Announcement",
                                    component: <div  id="corpAnnouncement" className="container-fluid">
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <UsStockCaTable />
                                            </div></div>
                                    </div>

                                },
                                {
                                    title: "Find US Underlying",
                                    component: <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <NavTabs tabs={[
                                                    {
                                                        title: "Sec Filing",
                                                        component: <>
                                                            <UsStockDataTable search={true} api={`/api/us-stocks-sec-filing`} />
                                                        </>

                                                    },
                                                    {
                                                        title: "Corporate Action",
                                                        component: <>
                                                            <UsStockDataTable search={true} api={`/api/us-stocks-corp-action`} />
                                                        </>

                                                    },
                                                ]} />   </div></div></div>


                                },
				 {
                                    title: "Find A Broker",
                                    component: <div  id="corpAnnouncement" className="container-fluid">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <FindABroker />
                                            </div>
                                            </div>
                                    </div>

                                },

                            ]} />

                        </div>
                    </div>


                </div>
                {/* <div className="light-blue-bg">
                    {this.state.contactus}
                </div> */}
            </>
        );
    }
}

export default USStocks;
