import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getDownloadIcon, parseAsHtml } from "./util";
import { TableCell } from "@material-ui/core";
import CustomMuiTablePagination from "./custom-muitable-pagination";
import EsgIssuerFilter from "./esg-issuer-filter";
import LinearProgress from '@material-ui/core/LinearProgress';

class IssuerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: props.api,
      columns: [],
      data: [],
      rowsPerPage: 10,
      page: 0,
      inProgress: true,
      issuerName: props.issuerName,
      issuerDataApi: '/api/issuer-data',
      mtnInUSD: 0,
      debtListingUnderMtnInUSD: 0,
      debtListingUnderStandaloneInUSD: 0,
      gssBondsInUSD: 0,
      //number animation
      displayedNumber: [],
      targetNumber: [],
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.filterData = this.filterData.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.api !== prevProps.api) {
      this.setState({ api: this.props.api }, () => {
        this.loadData(this.state.api);
      });
    }
  }
  componentDidMount() {
    try {
      if (this.state.api && this.state.api !== "") {
        this.loadData(this.state.api);
	this.loadIssuerData(this.state.issuerDataApi);
      }
    } catch (e) {
      console.log(e);
    }
  }
  loadData(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          data: data.data,
          columns: data.columns,
          page: 0,
          inProgress: false
        });
      })
      .catch((e) => {
        console.log("error in loading table data", e);
      });
  }

  handleChangePage = (newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (val) => {
    this.setState({
      rowsPerPage: +val,
      page: 0,
    });
  };

  filterData(name, documentType) {
    fetch(this.state.api)
      .then((resp) => resp.json())
      .then((data) => {
        if (name) {
          let filterdData = data.data.filter((el) => {
            return el.ISSUERNAME === name;
          });
          this.setState({ data: filterdData });
        }
        if (documentType) {
          let filterdData = data.data.filter((el) => {
           return el.TYPEOFDOC === documentType
          });
          this.setState({ data: filterdData });
        }
        if (name && documentType) {
          let filterdData = data.data.filter((el) => {
            return el.ISSUERNAME === name && el.TYPEOFDOC === documentType;
          });
          this.setState({ data: filterdData });
        }
      })
      .catch((e) => {
        console.log("error in filtering data", e);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.inProgress ? <LinearProgress/> : <><div className="mb-2">
          <EsgIssuerFilter
	    issuerName={this.state.issuerName}
            resetcallback={this.loadData}
            callback={this.filterData} />
        </div><TableContainer>
            <Table id="issuer-table" className="table customize-table ">
              <TableHead>
                <TableRow>
                  {this.state.columns.map((col) => {
                    return (
                      <TableCell className="text-left" key={col} scope="col">
                        {col}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <tbody>
                {this.state.data.length > 0 ? (
                  this.state.data
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                    )
                    .map((td, i) => {
                      return (
                        <TableRow key={i}>
                          {Object.keys(td).map((item, j) => {
                            return (td[item].includes("http://") || td[item].includes("https://")) ?
                              <TableCell key={`${j}${i}`} className="text-left"><a target="_blank"
                                rel="noopener noreferrer" href={td[item]}>{getDownloadIcon(td[item])}</a></TableCell> :
                              <TableCell key={`${j}${j}`} className="text-left">{td[item]}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell className="no-records" colSpan={7}>
                      No Records
                    </TableCell>
                  </TableRow>
                )}
              </tbody>
              <CustomMuiTablePagination
                count={this.state.data.length}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
                changePage={this.handleChangePage} />
            </Table>
          </TableContainer></> }
        
      </div>
    );
  }
}

export default IssuerTable;
