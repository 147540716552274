import { React, useEffect, useState } from 'react';
import CSVDownloader from './components/download-csv';

const MasalaBonds = () => {

    const [bondData, setBondData] = useState([]);
    const baseApi = '/api/bonds?type=MASALA';


    const fetchData = async () => {
        try {
            const resp = await fetch(baseApi);
            const jsonData = await resp.json();
            setBondData(jsonData);
        } catch (error) {
            console.log('error in fetching data', error);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <div className="row">
                <div class="col-md-12">
                    <div class="static-para">
                        <p>List your Rupee-denominated bonds on NSE IFSC</p>
                        <p>Masala bonds are rupee-denominated bonds and a significant source of funding for many sovereigns, corporates and bank through out the world</p>
                    </div>
                </div>
                <div class="col-md-3 mb-2"><div class="issueinfo-card"><div class="issue-img">
                    <img src="https://uat.nseix.com/nseix/sites/default/files/2022-01/Capture_3.PNG" alt="capture" />
                </div>
                    <p>MTN Established over USD 48.8 Billion</p>
                </div>
                </div>
                <div class="col-md-3 mb-2"><div class="issueinfo-card"><div class="issue-img">
                    <img src="https://uat.nseix.com/nseix/sites/default/files/2022-01/Capture1_6.PNG" alt="capture" />
                </div>
                    <p>Option to raise funds in multiple currencies</p>
                </div>
                </div>
                <div class="col-md-3 mb-2">
                    <div class="issueinfo-card">
                        <div class="issue-img">
                            <img src="https://uat.nseix.com/nseix/sites/default/files/2022-01/Capture2_3.PNG" alt="capture" />
                        </div>
                        <p>No minimum market capitalisation requirement</p>
                    </div>
                </div>
                <p className="col-md-4 my-3">
                    <a className="btn flat-button-orange external" target="_blank"
                        rel="noopener noreferrer" href="/listing/debt/listing-process"
                        title="How to list Foreign Currency&nbsp; Bonds on our Platform">HHow to list Masala Bonds on our Platform
                    </a>
                </p>
                <div className="col-md-12 pt-2 mb-2">
                    <div className="notes-section">
                        <h3 className="notes-title">List of Masala Bonds</h3>
                    </div>
                </div>
                <div className="d-flex justify-content-end ">
                    <div className="mr-2">
                        <CSVDownloader data={bondData} csvname={"MasalaBonds"} />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="responsive-static-table static-table" id="MasalaBonds">
                        <table border="1" cellPadding="1" cellSpacing="1">
                            <thead>
                                <tr>
                                    <th scope="col">ISSUER</th>
                                    <th scope="col">ISIN</th>
                                    <th scope="col">Issue Size</th>
                                    <th scope="col">Coupen Rate(%)</th>
                                    <th scope="col">Issue Date</th>
                                    <th scope="col">Credit Rating</th>
                                    <th scope="col">Listing Date</th>
                                    <th scope="col">Maturity Date</th>
                                    <th scope="col">Under MTN Programme</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bondData != undefined && bondData.length > 0 && bondData.map((bond) =>
                                    <tr>
                                        <td>{bond.ISSUER}</td>
                                        <td>{bond.ISIN}</td>
                                        <td>{bond.ISSUE_SIZE}</td>
                                        <td>{bond.COUPONRATE}</td>
                                        <td>{bond.ISSUEDATE}</td>
                                        <td>{bond.CREDINGRATING}</td>
                                        <td>{bond.LISTINGDATE}</td>
                                        <td>{bond.MATURITYDATE}</td>
                                        <td>{bond.MTN == null ? '-' : bond.MTN}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MasalaBonds;