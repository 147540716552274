import { React, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { getDownloadIcon } from './util';


function FindABroker() {

  const [rowData, setRowData] = useState([]);
  const [urlData, setUrlData] = useState();
  const api = '/api/membership-directory';
  const apiUrl = 'https://www1.nseix.com/api/contents/page?url=/globalstocks/membershipDirectoryFile';

  const fetchData = async () => {
    try {
      const resp = await fetch(api);
      const jsonData = await resp.json();
      setRowData(jsonData.data);
    } catch (error) {
      console.log('error in fetching data', error);
    };
  };
  useEffect(() => {
    fetchData();
    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (response) => {
          if(response.content.field_components.length > 0){
            setUrlData(response.content.field_components[0].file_url);
          }else{
            console.log('url is not present');
          }
        }
      )
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className='card' >
      <div className="d-flex download">
        <div className="mr-2" style={{ marginLeft: '15px', marginTop: '15px' }}>
          <Tooltip title="Membership Directory" placement="right">
              <a target="_blank" rel="noopener noreferrer" download="download" className="download-link"
              href={urlData}>{getDownloadIcon(urlData)}Membership Directory</a>
          </Tooltip>
        </div>
      </div>
      <div className='card-body'>
        <TableContainer>
          <Table className="table customize-table"  >
            <TableHead style={{ height: '48px' }}>
              <TableRow style={{ backgroundColor: '#392d7d', color: 'white' }}>
                <TableCell style={{ color: '#fff', width: '100px' }}>Sr.No.
                </TableCell>
                <TableCell style={{ color: '#fff', width: '100px' }} >TM Code
                </TableCell>
                <TableCell style={{ color: '#fff', width: '300px' }}>Member Name
                </TableCell>
                <TableCell style={{ color: '#fff', width: '240px' }}>Contact Person
                </TableCell>
                <TableCell style={{ color: '#fff', width: '280px' }}>E-Mail ID
                </TableCell>
                <TableCell style={{ color: '#fff', width: '150px' }}>Contact No.
                </TableCell>
                <TableCell style={{ color: '#fff' }}>Address
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((data) => {
                return (
                  <TableRow>
                    <TableCell >{data.SERIALNUM}</TableCell>
                    <TableCell >{data.TMCODE}</TableCell>
                    <TableCell >{data.MEMBERNAME}</TableCell>
                    <TableCell style={{whiteSpace:'pre-line'}}>{data.CONTACTPERSON}</TableCell>
                    <TableCell >{data.EMAILID}</TableCell>
                    <TableCell >{data.CONTACTNUM}</TableCell>
                    <TableCell >{data.MEMBERADDRESS}</TableCell>
                  </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default FindABroker;
