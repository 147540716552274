import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default function Graph({dataX, dataY}) {

const options = {
  chart:{
    type: "area"
  },
  rangeSelector: {
enabled:false,
  },
  plotOptions: {
    series: {
      enableMouseTracking: true
    }
  },
  
  series: [
    {
      data:[dataX],
      tooltip:{
        valurDecimals :2,
      }
    }
  ],
  xAxis: {
    categories: [dataY]
  }
};
  return (
    <div className='Graph'>
      <HighchartsReact 
      highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options} />
    </div>)
}
